import Glide from '@glidejs/glide'

if ( $('.js-header-front-slider').length )
{
    new Glide('.js-header-front-slider', {
        type: 'slider',
        perView: 1,
        gap: 30,
        autoplay: 3000,    
    }).mount();
}