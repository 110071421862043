// Import Styles
import '../sass/styles.scss';

// Import Plugins
import 'bootstrap';

// Import Sliders
import './slider/testimonial-slider';
import './slider/header-front-slider';

(function () { 

    $(document).ready(function() 
    {
        // Hamburger Menu
        $('.hamburger').on('click', function(e) 
        {
            e.preventDefault();

            $(this).toggleClass('is-active');
            $('.js-header__navigation').toggleClass('is-active');
        });

        // Double Tap to Go
        if (window.matchMedia('(max-width: 768px)').matches) {
            var previousTarget = null;

            $('.menu-item-has-children > a').on('click', function(e) 
            {
                $('.sub-menu').removeClass('js-open');

                if (this === previousTarget) {
                    // Do nothing...
                } else {
                    e.preventDefault();
                    previousTarget = this;
                }

                $(this).next('.sub-menu').toggleClass('js-open');
            });
        }

        // Gallery
        $('.js-property-gallery-button').on('click', function(e) 
        {
            e.preventDefault();
            $('.js-property-gallery-popup').toggleClass('is-active');
        });

        // Smooth Scroll
        $('a[href^="#"]').on('click', function(event) {
            event.preventDefault();
    
            var target = $($.attr(this, 'href'));
            var offset = $('.c-header').outerHeight();
    
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - offset
                }, 500)
            }
        });
    });

    $(window).scroll(function() 
    {
        
    });

})();